<template>
  <div class="form--group" :class="{ 'has-error': validation.hasError('unitName') }">
    <div class="left--col">
      <span>{{ $t('addListing.unitDetail') }}</span>
    </div>
    <div class="right--col">
      <div class="row my-0">
        <div class="col-6 py-0" style="padding-right: 8px;">
          <v-text-field
            :label="$t('addListing.placeholder.unitName')"
            outlined
            :hide-details="true"
            v-model="unitName"
            name="unit_name"
            :class="validation.hasError('unitName') ? 'is-invalid' : ''"
            class="basic--input input--margin"
          />
          <span class="val-error" v-if="validation.hasError('unitName')">{{
            validation.firstError('unitName')
          }}</span>
        </div>
        <div class="col-6 py-0" style="padding-left: 8px;">
          <v-text-field
            :label="$t('addListing.placeholder.totalFloor')"
            outlined
            :hide-details="true"
            single-line
            type="number"
            v-model="totalNumberOfFloor"
            name="total_number_of_floor"
            :class="validation.hasError('totalNumberOfFloor') ? 'is-invalid' : ''"
            class="basic--input input--margin"
          />
          <span class="val-error" v-if="validation.hasError('totalNumberOfFloor')">{{
            validation.firstError('totalNumberOfFloor')
          }}</span>
        </div>
      </div>
      <div class="row my-0">
        <div class="col-6 py-0" style="padding-right: 8px;">
          <v-text-field
            :label="$t('addListing.placeholder.floorLocation')"
            outlined
            :hide-details="true"
            v-model="floor"
            name="floor"
            :class="validation.hasError('floor') ? 'is-invalid' : ''"
            class="basic--input input--margin"
          />
          <span class="val-error" v-if="validation.hasError('floor')">{{
            validation.firstError('floor')
          }}</span>
        </div>
        <div class="col-6 py-0" style="padding-left: 8px;">
          <v-text-field
            :label="$t('addListing.placeholder.unitNumber')"
            outlined
            :hide-details="true"
            v-model="unitField"
            name="unit_field"
            :class="validation.hasError('unitField') ? 'is-invalid' : ''"
            class="basic--input input--margin"
          />
          <span class="val-error" v-if="validation.hasError('unitField')">{{
            validation.firstError('unitField')
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';

export default {
  name: 'detail-unit-section',
  computed: {
    selectedLandCertificateId: {
      get() {
        return this.$store.getters['v2/listingForm/sect2/detail/selectedLandCertificateId'];
      },
      set(value) {
        return this.$store.commit(
          'v2/listingForm/sect2/detail/SET_SELECTED_LAND_CERTIFICATE',
          value,
        );
      },
    },
    totalNumberOfFloor: {
      get() {
        return this.$store.state.v2.listingForm.sect2.detail.totalNumberOfFloor;
      },
      set(value) {
        return this.$store.commit('v2/listingForm/sect2/detail/SET_TOTAL_NUMBER_OF_FLOOR', value);
      },
    },
    floor: {
      get() {
        return this.$store.state.v2.listingForm.sect2.detail.floor;
      },
      set(value) {
        return this.$store.commit('v2/listingForm/sect2/detail/SET_FLOOR', value);
      },
    },
    unitField: {
      get() {
        return this.$store.state.v2.listingForm.sect2.detail.unit;
      },
      set(value) {
        return this.$store.commit('v2/listingForm/sect2/detail/SET_UNIT', value);
      },
    },
    unitName: {
      get() {
        return this.$store.state.v2.listingForm.sect2.detail.unitName;
      },
      set(value) {
        return this.$store.commit('v2/listingForm/sect2/detail/SET_UNIT_NAME', value);
      },
    },
  },
  methods: {
    validate: function() {
      return this.$validate().then(
        function(success) {
          return !!success;
        }.bind(this),
      );
    },
  },
  validators: {
    totalNumberOfFloor: function(value) {
      return Validator.value(value).between(
        1,
        1000,
        this.$i18n.t('errors.addListing.totalFloor.between', {
          minChar: 1,
          maxChar: 1000,
        }),
      );
    },
    floor: function(value) {
      return Validator.value(value).maxLength(
        200,
        this.$i18n.t('errors.addListing.floor.max', { maxChar: 200 }),
      );
    },
    unitName: function(value) {
      return Validator.value(value).maxLength(
        50,
        this.$i18n.t('errors.addListing.unitName.max', { maxChar: 50 }),
      );
    },
    unitField: function(value) {
      return Validator.value(value).maxLength(
        50,
        this.$i18n.t('errors.addListing.unit.max', { maxChar: 50 }),
      );
    },
  },
};
</script>

<style scoped></style>
